<template>
    <div class="AbstractCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-form @submit.native.prevent :inline="true" :model="form" :rules="rules" ref="createForm" label-width="80px">
            <el-card shadow="never">
                <el-button
                    type="primary"
                    @click="handleSave"
                    size="small"
                    :disabled="form.detailParams.length != 0 ? false : true"
                    >保存
                </el-button>
                <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="80" align="center" />
                    <el-table-column label="名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.names'" :rules="rules.names">
                                <el-input v-model.trim="scope.row.names" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" width="160">
                        <template slot-scope="scope">
                            <el-form-item :rules="rules.types" :prop="'detailParams.' + scope.$index + '.types'">
                                <ef-abstract-type-select v-model="scope.row.types" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import StockBizCommon from 'js/StockBizCommon';
import EfAbstractTypeSelect from 'components/EfAbstractTypeSelect';
import { duplicateValidateRule } from 'js/validate/ValidateCommonRule';

export default {
    name: 'AbstractCreate',
    components: { EfAbstractTypeSelect },

    data() {
        return {
            form: {
                detailParams: [],
            },
            detailParamPropertyArr: ['names', 'types'],
            rules: {
                names: [
                    { required: true, message: '请填写名称', trigger: ['blur'] },
                    duplicateValidateRule(
                        (currentIdx, name) => {
                            const duplicated = this.form.detailParams.find((e, index) => {
                                return currentIdx !== index && name === e.names;
                            });
                            return duplicated !== undefined;
                        },
                        '名称重复',
                        (currentIdx) => {
                            this.form.detailParams[currentIdx].names = '';
                        }
                    ),
                ],
                types: [{ required: true, message: '请选择类型', trigger: 'change' }],
            },
        };
    },
    methods: {
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                //将商品详情列表转换成多字段数组形式
                const createParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArr
                    ),
                };
                //删除非提交必要字段数据
                delete createParam.detailParams;
                await this.$efApi.stockAbstractApi.create(createParam);
                this.goBackAndReload();
            });
        },
        handleAdd() {
            this.form.detailParams.push({
                names: '',
                types: '',
            });
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
    },
};
</script>
